import React from "react"

const OpeningHours = () => (
  <section className="opening-hours background-yellow">
    <span className="next-section" id="oeffnungszeiten"></span>
    <h2>Öffnungszeiten</h2>
    <ul>
      <li>
        <dl>
          <dt>Montag</dt>
          <dd>8:30 – 12:30</dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>Dienstag – Freitag</dt>
          <dd>
            8:30 – 12:30
            <br />
            14:00 – 18:00
          </dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>Samstag</dt>
          <dd>8:30 – 13:30</dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>Sonntag</dt>
          <dd>
            Nur auf Vorbestellung,
            <br />
            Abholung möglich.
          </dd>
        </dl>
      </li>
    </ul>
  </section>
)

export default OpeningHours
