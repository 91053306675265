import React from "react"
import { useProducts } from "../hooks/useProducts"
import Product from "./product"


const ProductList = () => {
    const products = useProducts();

    return (
        <section className="product-list">
            <span className="next-section" id="unser-angebot"></span>
            <h2>Unsere Angebote</h2>
            {products.map((props, index) =>
                <Product
                    key={index}
                    {...props}
                />
            )}
        </section>
    );
}

export default ProductList
