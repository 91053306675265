import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const Map = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBRYRlWNJtr3aoOJhSeWfNUxFqYzdfWdEY",
        loadingElement: <div className="map" />,
        containerElement: <div className="map-wrapper"><div className="map" /></div>,
        mapElement: <div className="map" />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
    return (
        <GoogleMap
            center={{ lat: 48.0521, lng: 7.79364 }}
            zoom={14}
            options={{
                mapTypeId: 'roadmap',
                mapTypeControl: false,
                streetViewControl: false,
                featureType: "poi",
                styles: [
                    {
                        featureType: "poi",
                        stylers: [
                            { visibility: "off" }
                        ]
                    }
                ]
            }}
        >
            <Marker
                position={{ lat: 48.052475, lng: 7.794000 }}
                icon={{
                    url: 'data:image/svg+xml;utf-8, \n' +
                        '<svg width="46px" height="46px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">\n' +
                        '<path fill="%23ffca48" d="M256,0C149.969,0,64,85.969,64,192s160,320,192,320s192-213.969,192-320S362.031,0,256,0z M256,320c-70.594,0-128-57.438-128-128S185.406,64,256,64s128,57.438,128,128S326.594,320,256,320z" ></path>\n' +
                        '</svg>'
                }}
            />
        </GoogleMap>
    );
})

export default Map;
