import PropTypes from "prop-types"
import React from "react"
import NumberFormat from 'react-number-format';
import Img from "gatsby-image"
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({
    html: true,
    linkify: false,
});


const Product = ({ title, slug, subtitle, description, price, images, variants}) => {
    return (
        <div className={`product`}>
            <Img
                className="product__image"
                fluid={images[0].fluid}
                key={images[0].fluid.src}
                alt={images[0].title} />
            <div className="product__content">
                <h4 className="product__subtitle">{subtitle}</h4>
                <h3 className="product__title">{title}</h3>
                <div
                    className="product__description"
                    dangerouslySetInnerHTML={{ __html: md.render(description.description) }} />
                <div className="product__price">
                    {variants && (
                        <span className="product__price-from">ab </span>
                    )}
                    <NumberFormat
                        className="product__price-amount"
                        value={price}
                        displayType={'text'}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        decimalScale={2}
                        fixedDecimalScale={2}
                        suffix={' €'} />
                    <small className="product__price-vat">inkl. MwSt.</small>
                </div>
                <div className="product__button">
                    <a
                        href="tel:004976654499"
                        className="button button__large"
                        // data-item-id={slug}
                        // data-item-price={price}
                        // data-item-url={slug}
                        // data-item-description={subtitle}
                        // data-item-name={title}
                        // data-item-quantity="1"
                        // disabled={false}
                        // data-item-custom1-name="Größe"
                        // data-item-custom1-options="klein|groß[+5.00]"
                    >
                        Jetzt anrufen
                        <small>+49&nbsp;7665&nbsp;4499</small>
                    </a>
                </div>
            </div>
        </div>
    );
}

Product.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    price: PropTypes.number.isRequired,
}

export default Product
