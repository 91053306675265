import { useStaticQuery, graphql } from "gatsby"

export const useProducts = () => {
    const { allContentfulProduct } = useStaticQuery(graphql`
      {
        allContentfulProduct(sort: {fields: sortOrder}) {
          edges {
            node {
              title
              slug
              subtitle
              price
              variants
              description {
                  description
              }
              images {
                fluid(maxWidth: 560) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `)

    return allContentfulProduct.edges.map((entry) => {
        return entry.node;
    });
}
