import React from "react"

const NewCI = () => (
    <section className="new-ci">
        <span className="next-section"></span>
        <h2>Kremp und Blumen</h2>
        <div>
            <p>Liebe Kundin, lieber Kunde,</p>
            <p>Falls du dich gewundert hast... In unserem Blumenladen gibt es einen kleinen Neustart. Wir freuen uns darauf, bei "Kremp und Blumen" auch in Zukunft für euch da zu sein.</p>
            <p>Liebe Grüße,<br />Dein Team Kremp</p>
        </div>
    </section>
);

export default NewCI
