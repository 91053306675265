import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Promotion from "../components/promotion"
import ProductList from "../components/product-list"
import NewCI from "../components/new-ci"
import OpeningHours from "../components/opening-hours"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <Seo title="Kremp und Blumen — Der schöne Blumenladen am Berg." />
    <NewCI />
    <ProductList />
    <OpeningHours />
    <Contact />
  </Layout>
)

export default IndexPage
